import mock1 from "../img/mock1.png"
import mock2 from "../img/mock2.png"
import mock3 from "../img/mock3.png"
import mock4 from "../img/mock4.png"
import mock5 from "../img/mock5.png"
import self from "../img/self.png"

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */


/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Matias",
    lastName: "Heikkilä",
    initials: "mh", // the example uses first and last, but feel free to use three or more if you like.
    position: "program develepor",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '☕',
            text: 'fueled by coffee'
        },
        {
            emoji: '🌎',
            text: 'based in the Finland'
        },
        {
            emoji: "💼",
            text: "Looking for work"
        },
        {
            emoji: "📧",
            text: "jmatte.heikkila@gmail.com"
        }
    ],
    socials: [
        {
            link: "https://www.snapchat.com/add/matteheikkila?share_id=8dSp7Z5tkE4&locale=en-FI",
            icon: 'fa fa-snapchat',
            label: 'facebook'
        },
        {
            link: "https://www.instagram.com/master86_fin/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/xRATH3Rx",
            icon: "fa fa-github",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/matias-heikkil%C3%A4-8aaa301ba/",
            icon: "fa fa-linkedin",
            label: 'linkedin'
        }
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Matias, a 20-year-old Turku native, combines diligence, conscientiousness, and tranquility in his approach to life and work. Fluent in Finnish, Swedish, and English, he leverages his multilingual skills in diverse settings. A proud graduate of Turun ammatti instituuttissa (Class of 2023), Matias is not only academically accomplished but also engages in coding, social activities with friends, and driving in his leisure time. ",
    skills:
        {
            proficientWith: [ 'c#', 'python', 'git', 'html', 'css', 'blender', 'unity'],
            exposedTo: ['nodejs', 'react', 'c++', 'github' ,'javascript',]
        }
    ,
    hobbies: [
        {
            label: 'gaming',
            emoji: '🎮'
        },
        {
            label: 'driving',
            emoji: '🚗'
        },
        {
            label: 'coding',
            emoji: '👨‍💻'
        },
        {
            label: 'friends',
            emoji: '😎'
        },
        {
            label: 'editing',
            emoji: '🎥'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Project 1",
            //live: "https://paytonpierce.dev", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "", // this should be a link to the **repository** of the project, where the code is hosted.
            image: mock1
        }

    ]
}